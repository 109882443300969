<template>
  <div class="project-detail-container" ref="container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ProjectDetail',
  async created() {
    this.$store.dispatch('serviceline/getAllServiceLines');
    await this.$store.dispatch('project/getProject', Number(this.$route.params.id));
    
    /*  
    Only select the simulation when we have a project. The simulations will also
    load concurrently so when both are done loading they'll trigger sending assets
    to the viewer automatically.
    */
    this.$store.dispatch('project/simulationAsset/resetState');  //Clears the list of simulations, the selectedSimulationId, the globalfilter, selectedsimulations, etc. before entering a new project
    this.$store.dispatch('project/simulationAsset/selectSimulation', Number(this.$route.params.configuration));
    if (this.$route.query.secondary) {
      this.$store.dispatch('project/simulationAsset/selectSecondaryViewerSimulation', Number(this.$route.query.secondary));
    }
    this.$store.dispatch('project/simulationAsset/getSimulations', { projectId: Number(this.$route.params.id), studyId: Number(this.$route.params.study) });
    this.$store.dispatch('project/selectStudyId', Number(this.$route.params.study));

  },
  watch: {
    $route(to, from) {
      let isSplitView = !!to.query.secondary;
      if(isSplitView) {
        // have to set secondary simulation to null and wait for next tick so that when it is set a change is noticed
        this.$store.dispatch('project/simulationAsset/selectSecondaryViewerSimulation', null);
        this.$nextTick(() => {
          this.$store.dispatch('project/simulationAsset/selectSecondaryViewerSimulation', Number(to.query.secondary));
        });
      }
      
      // set selected study id base on route 
      if(to.params.study != from.params.study) {
        this.$store.dispatch('project/simulationAsset/resetState');
        this.$store.dispatch('project/simulationAsset/selectSimulation', Number(to.params.configuration));
        this.$store.dispatch('project/simulationAsset/getSimulations', { projectId: Number(to.params.id), studyId: Number(to.params.study) });
        this.$store.dispatch('project/selectStudyId', Number(to.params.study));
      }
    }
  }
};
</script>

<style scoped>
.project-detail-container {
  width: 100%;
  height: calc(100% - 6.25rem);
}
</style>